import React from "react";
import "./App.css";
import Gif from "./coin.gif";

function App() {
  // const videoRef = useRef(null);

  // useEffect(() => {
  //   const videoElement = videoRef.current;

  //   // Add event listeners to handle play
  //   const handleCanPlay = () => {
  //     const playPromise = videoElement.play();

  //     if (playPromise !== undefined) {
  //       playPromise
  //         .then(() => {
  //           // Video started playing successfully
  //         })
  //         .catch((error) => {
  //           console.error("Autoplay prevented:", error);
  //         });
  //     }
  //   };

  //   if (videoElement) {
  //     videoElement.addEventListener("canplay", handleCanPlay);
  //   }

  //   return () => {
  //     if (videoElement) {
  //       videoElement.removeEventListener("canplay", handleCanPlay);
  //     }
  //   };
  // }, []);

  return (
    <>
      <div className="background-container">
        <img
          src="https://storage.googleapis.com/3d-container/theapei/Layer%201.png"
          className="img-coming"
          alt="apehouse"
        />
      </div>
      <div className="parent">
        <div className="sub">
          <img
            id="loader-vid"
            muted
            loop
            playsInline
            src={Gif}
            className="json-icon"
            alt="apehouse"
            style={{
              backgroundColor: "transparent",
              background: "transparent",
            }}
          />
          {/* <video
            id="loader-vid"
            ref={videoRef}
            muted
            loop
            playsInline
            src="https://storage.googleapis.com/3d-container/theapei/APEi_Coinv2_1.webm"
            className="json-icon"
            alt="apehouse"
            style={{
              backgroundColor: "transparent",
              background: "transparent",
            }}
          /> */}
          <img
            src="https://storage.googleapis.com/3d-container/theapei/Coming%20Soon.png"
            className="coming-soon"
            alt="apehouse"
          />
          <img
            src="https://storage.googleapis.com/3d-container/theapei/Vector%20Smart%20Object.png"
            className="ape-chain"
            alt="apehouse"
          />
        </div>
      </div>
    </>
  );
}

export default App;
